<template>
  <div class="contents liveInfo">
    <div class="title flexB" style="margin: 0px; padding: 0px;">
      <h1>{{ $t("overall-drone-title") }}</h1>
<!--      <div>
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">{{ $t("search-keyword-title") }}</p>
        <select v-model="keywordType">
          <option value="eventControl">{{ $t("search-keyword-all") }}</option>
          <option value="deviceName">{{ $t("device-data-device-name") }}</option>
          <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
          <option value="eventName">{{ $t("device-data-manager-event-name") }}</option>
          <option value="userName">{{ $t("device-data-manager-operator") }}</option>
        </select>
        <input
            type="text"
            v-model="keyword"
            @keydown.enter="logSearch"
        />
        <div class="buttonWrap" style="display: flex; margin-top: 0;">
          <button class="point medium" @click="logSearch" style="margin-left: 20px; width: 120px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="flexC">
        <p class="bold">{{ $t("device-data-start-flight-time") }}</p>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
        <span>~</span>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
    </div>
    <div class="tabBox recode">
      <div class="contents" style="padding:0;">
        <div class="one">
          <div class="boxCol2-2 flexB">
            <div class="tableWrap box" style="width: calc(60% - 2px); height: 100%; min-height: 523px; border: none; padding: 0;">
              <div class="tableBox">
                <div style="min-height: 453px;">
                  <table style="table-layout: fixed; overflow-x: auto;">
                    <colgroup>
                      <col style="width: 5%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 10%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 15%;"/>
                      <col style="width: 10%;"/>
                    </colgroup>
                    <tr>
                      <th>No</th>
                      <th>{{ $t("device-data-start-flight-time") }}</th>
                      <th>{{ $t("device-data-end-flight-time") }}</th>
                      <th>{{ $t("device-data-working-flight-time") }}</th>
                      <th>{{ $t("device-data-manager-event-name") }}</th>
                      <th>{{ $t("device-data-device-name") }}</th>
                      <th>{{ $t("device-data-manager-dept-name") }}</th>
                      <th>{{ $t("device-data-manager-operator") }}</th>
                    </tr>
                    <tr v-if="visibleTotal == 0">
                      <td colspan="8" style="text-align: center;">
                        {{ $t("search-not-found") }}
                      </td>
                    </tr>
                    <tr v-for="(data, i) in visibleList" :key="i" @click="logHandleSelectEl(data, i)" :class="{ active: data.deviceLogId == (selectData != null && selectData.deviceLogId) }">
                      <td>{{ visibleTotal - (size * currentPage + i) }}</td>
                      <td>
                        {{ moment(data.startTime).format("YYYY-MM-DD HH:mm") }}
                      </td>
                      <td>
                        {{ moment(data.endTime).format("YYYY-MM-DD HH:mm")  }}
                      </td>
                      <td>
                        {{ logTimeToJoinTime(data.startTime, data.endTime) }}
                      </td>
                      <td>
                        {{ data.eventTitle }}
                      </td>
                      <td :title="data.deviceName">
                        {{ data.deviceName }}
                      </td>
                      <td>
                        {{ data.workerDepartmentName }}
                      </td>
                      <td>
                        {{ data.workerName }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="pagination">
                  <el-pagination
                      small
                      layout="prev, pager, next"
                      :total="visibleTotal"
                      :page-size='size'
                      @current-change="handleCurrentChange"
                      :current-page="currentPage + 1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="box" style="width: calc(40% - 2px); height: 100%; min-height: 523px; border:none; padding: 0;">
              <div class="mapWrap" style="width: 100%">
                <div class="map" style="min-height: 403px">
                  <Map style="width: 100%; height: 100%; position: absolute;" ref="map" :loadMapName="loadMap" :isRightLngLat="true" @play-marker-lng-lat="playMarkerLngLat"/>
                  <span v-if="locationSize == 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                    {{$t("popup-location-not-found")}}
                  </span>
                  <div class="box mapVodInfo" >
                    <div>
                      <div class="flexB droneVal" style="width: 100%; margin: 2px 0;">
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="bold DataName">고도</span>
                          <span class="vodData">{{ doneData.height == null ? 'N/A' : `${doneData.height.toFixed(0)} m` }}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="bold DataName">수평</span>
                          <span class="vodData">{{doneData.hspeed == null ? 'N/A' :  `${doneData.hspeed.toFixed(0)} m/s`}}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="bold DataName">수직</span>
                          <span class="vodData">{{ doneData.vspeed == null ? 'N/A' : `${doneData.vspeed.toFixed(0)} m/s` }}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="bold DataName">거리</span>
                          <span class="vodData">{{ doneData.distance == null ? 'N/A' : `${doneData.distance.toFixed(0)} m` }}</span>
                        </div>
                      </div>
                      <div class="flex" style="justify-content: center;">
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="DataName bold">위도</span>
                          <span class="vodData">{{lat == null ? 'N/A' : `${lat.toFixed(9)}`}}</span>
                        </div>
                        <div class="droneData flex" style="justify-content: flex-start !important;">
                          <span class="DataName bold">경도</span>
                          <span class="vodData">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="deviceInfo">
                <div class="progress-bar">
                  <input style="width: 100%; height: 3px;" type="range" id="volume" name="volume" min="0" v-model="progressbarValue" :max="progressbarTotal">
                  <img :src="!isMapPlay ? require('@/assets/images/icon_svg/ic-play.svg') : require('@/assets/images/icon_svg/ic-pause.svg')" alt="icon" @click="onMapPlay">
                  <span class="first">{{ secConvert(progressbarValue) }}</span>
                  <span class="first">/</span>
                  <span class="end">{{ secConvert(progressbarTotal) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.mapVodInfo{
  width: 360px;
  position: absolute;
  padding: 5px 16px;
  z-index: 10;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
}
.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
  margin-left: 5px;
}
.DataName{
  color: #6ebeff;
  font-size: 1.2rem;
}
.progress-bar {
  width: 100%;
  height: 50px;
  position: relative;
  bottom: 0;
  margin: -61px 0 0;
  padding: 3.8px 19.8px 3px 18.6px;
  background-color: rgba(30, 30, 30, 0.5);
  text-align: right;
}
.progress-bar > img {
  margin-top: 4px;
  float: left;
}
.progress-bar > div {
  display: contents;
}
.progress-bar > span.first {
  display:inline-block;
  padding-top: 4px;
  color : white;
  margin-right: 10px;
}
.progress-bar > span.end {
  display:inline-block;
  padding-top: 4px;
  margin-top: 4px;
  color : white;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";


import { fetchEvent } from "@/api/event";
import {fetchDeviceLogList, getDeviceImgUrl, getDeviceImgDefault} from "@/api/device";
import { fetchPointList, getPointTypeName } from "@/api/point";

//import {fetchWorkerLogList} from "@/api/worker";
import { fetchLocation } from "@/api/location";
import { format } from "@/mixins/format";

import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

import { baseUrl } from "@/config/env";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
export default {
  name: "Home",
  components: { Map, Datepicker },
  mixins: [format],
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
    progressbarValue : function(value){
      this.$refs.map.onPlayUpdatePosition(value);
    },
    isMapPlay : function(value){
      if(value) {
        this.interval = setInterval(() => {
          if(this.progressbarValue == this.progressbarTotal) {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }
          this.progressbarValue =  Number(this.progressbarValue) + 1;
        }, 1000);
      } else {
        if(this.interval != null) {
          clearInterval(this.interval);
        }
      }
    },
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 11, sub: 0 });
  },
  data() {
    return {
      moment: moment,
      isLoading:false,
      visible: "LOG",
      eventInfo: null,
      baseUrl: baseUrl,
      loadMap : 'kakao',

      interval: null,
      selectEl: null,
      ko: ko,

      visibleList: [],
      visibleTotal : 0,
      selectData : null,

      joinSelectId : null,

      //이동기록 변수
      progressbarTotal: 0,
      progressbarValue : 0,
      isMapPlay : false,
      selectMemo : null,
      locationSize : null,
      location: [],

      //검색 조건
      startYmd: null,
      endYmd: null,
      size: 10,
      currentPage: 0,
      keywordType: "eventControl",
      statuses: null,
      keyword: null,
      eventId: null,

      doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
      },
      lng : null,
      lat : null,
    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  async mounted() {
   /* this.getEvent();*/
    this.logHandleTab();
    this.initMapData(this.currentLang);
  },
  methods: {
    handleRefresh(){
      this.reset();
      this.logSearch();
    },
    playMarkerLngLat(lng, lat, index) {
      this.$refs.map.onSetLngLat(lng,lat);
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.doneData.distance = locationData.distance;
        this.doneData.hspeed = locationData.hspeed;
        this.doneData.vspeed = locationData.vspeed;
        this.doneData.height = locationData.height;
        this.doneData.battery = locationData.battery;
        this.lng = lng;
        this.lat = lat;
      }
    },
    initDoneData(){
      this.doneData.distance = null;
      this.doneData.hspeed = null;
      this.doneData.vspeed = null;
      this.doneData.height = null;
      this.doneData.battery = null;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        this.$refs.map.onShowButtonTypeChange();
        //this.$refs.map.onShowLngLat();
     /*   if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }*/
        this.getEventPoint();

        if(this.selectData != null) {
          var dateFrom = this.selectData.checkInDt
          var dateTo = this.selectData.checkOutDt
          this.getLocation(this.selectData, dateFrom, dateTo);
        }
      })
    },
    getDeviceImg(data){
      return data.defaultIsImage ? getDeviceImgDefault(data.manufacturerId, data.modelId) : getDeviceImgUrl(data.accountId, data.deviceId) + '?date='+(new Date)
    },
    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList(params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.logHandleTab();
    },

    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "eventControl";
    },


    async logHandleTab() {
      this.isLoading = true;
      this.isMapPlay = false;
      await this.getDeviceLogList();
    },

    /*클릭 했을 때 data*/
    async logHandleSelectEl(data, i){
      this.eventId = data.eventId;
      await this.getEvent(this.eventId).then(() => {
        this.isMapPlay = false;

        this.$refs.map.onClearMapData();
        this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
        this.getEventPoint();

        var id = data.deviceLogId + "_" + i;
        if(this.selectEl == id) {
          this.selectEl = null;
          this.selectData = null;
        } else {
          this.selectEl = id;
          this.selectData = data;
        }

        if(this.selectEl != null){
          var dateFrom = data.startTime
          var dateTo = data.endTime
          this.getLocation(this.selectData, dateFrom, dateTo);
        } else {
          this.getLocation(this.selectData);
        }
      });
    },

    /*드론 운영 목록*/
    async getDeviceLogList(){
      let params = {
        /*eventId : this.eventId,*/
        pageSize : this.size,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        if (res.data.result == 0) {
          this.visibleList = res.data.data.content
          this.visibleTotal = res.data.data.total
        }
      });

    },
    logSearch(){
      this.currentPage = 0;
      this.getDeviceLogList();
    },

    logTimeToJoinTime(checkInDt, checkOutDt){
      var inDate = new Date(moment(checkInDt));
      var outDate = new Date(moment(checkOutDt));

      var time = outDate.getTime() - inDate.getTime();
      if(time < 0){ //time 이 음수일 때 발생하는 오류 방지
        time = 0;
      }
      return this.secConvert(Math.floor(time/ 1000));
    },
    secConvert(seconds) {
      var hour = parseInt(seconds/3600) < 10 ? '0'+ parseInt(seconds/3600) : parseInt(seconds/3600);
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return hour+":"+min+":" + sec;
    },

    /*해당 현장의 위치 및 모든 정보조회*/
    async getEvent(eventId) {
      if(eventId == undefined) {
        return;
      }
      await fetchEvent(eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
        }
      });
    },
    onMapPlay(){
      /*이동기록 확인 팝업*/
      switch (this.locationSize){
        case 0 :
          alert("이동기록이 없을 시 재생할 수 없습니다.");
          break;
        case null :
          alert("이력 선택 후 재생해 주세요.");
          break;
        default :
          this.isMapPlay = ! this.isMapPlay;
          break;
      }
    },
    async getLocation(data, dateFrom, dateTo) {
      this.location = [];
      this.initDoneData();

      this.progressbarTotal = 0;
      this.progressbarValue = 0;
      this.locationSize = null;
      if(data == null) {
        return;
      }

      let params = {
        refId: data.deviceLogId,
        eventId : this.eventId,
        'localDateRange.from' : null,
        'localDateRange.to' : null,
      };

      if(dateFrom != undefined && dateTo != undefined) {
        params['localDateRange.from'] = moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss");
        params['localDateRange.to'] = moment(dateTo).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      fetchLocation(params).then((res) => {
        if(res.data.result == 0) {
          this.locationSize = res.data.data.content.length;
          if(res.data.data.content.length > 0) {
            res.data.data.content.sort(function(a, b) {
              return a.index - b.index;
            })
            this.location = res.data.data.content;
            this.playMarkerLngLat(this.location[0].lng , this.location[0].lat, 0);

            var first = new Date(moment(res.data.data.content[0].time));
            var last = new Date(moment(res.data.data.content[res.data.data.content.length - 1].time));
            var maxValue = last.getTime() - first.getTime();
            this.progressbarTotal = maxValue/ 1000;

            var firstData = res.data.data.content[0];
            this.$refs.map.onLngLatToMoveCenter(firstData.lng, firstData.lat)
            var mapLineData = new MapLineData();
            mapLineData.id = this.contentsId;
            mapLineData.locationGroup = res.data.data.content;
            this.$refs.map.onPlayCreateData(mapLineData);
          }
        } else {
          this.location = [];
          this.locationSize = 0;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
